import * as React from "react"
import { Link } from "gatsby"
import {Container, Wrapper, Article_Lg, ArticleImg, ArticleTitle_Lg, ByLine_Lg, Article_Sm, ArticleTitle_Sm, ByLine_Sm, Goto} from "../styles/blogStyles"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ExampleImg from "../images/image1.png"

const BlogsPage = () => (
  <Layout>
    <Seo title="Blogs" />
    <Container>
        <Wrapper>
            <Article_Lg>
                <Goto to="/BlogExample/">
                <ArticleImg src={ExampleImg}/>
                <ArticleTitle_Lg>This is a Placeholder Title</ArticleTitle_Lg>
                <ByLine_Lg>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Lg>
                </Goto>
            </Article_Lg>
            <Article_Lg>
            <Goto to="/BlogExample/">
                <ArticleImg src={ExampleImg}/>
                <ArticleTitle_Lg>This is a Placeholder Title</ArticleTitle_Lg>
                <ByLine_Lg>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Lg>
            </Goto>
            </Article_Lg>
            <Article_Sm>
                <ArticleImg src={ExampleImg}/>
                <ArticleTitle_Sm>This is a Placeholder Title</ArticleTitle_Sm>
                <ByLine_Sm>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Sm>
            </Article_Sm>
            <Article_Sm>
                <ArticleImg src={ExampleImg}/>
                <ArticleTitle_Sm>This is a Placeholder Title</ArticleTitle_Sm>
                <ByLine_Sm>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Sm>
            </Article_Sm>
            <Article_Sm>
                <ArticleImg src={ExampleImg}/>
                <ArticleTitle_Sm>This is a Placeholder Title</ArticleTitle_Sm>
                <ByLine_Sm>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Sm>
            </Article_Sm>
        </Wrapper>
    </Container>
  </Layout>
)

export default BlogsPage
