import styled from 'styled-components'
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
`;

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 80%;
    margin-top: 50px;
`;

const Article_Lg = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 49%;
    margin-bottom: 50px;
`;

const ArticleImg = styled.img`
    margin: 0px;
    width: 100%;
    object-fit: cover;
    height: fit-content;
`;

const ArticleTitle_Lg = styled.div`
    font-size: 22px;
    text-align: left;
    width: 100%;
    padding-top: 15px;
`;

const ByLine_Lg = styled.div`
    font-size: 16px;
    text-align: left;
    width: 100%;
    padding-top: 5px;
`;

const Article_Sm = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 33%;
    margin-bottom: 20px;
`;

const ArticleTitle_Sm = styled.div`
    font-size: 18px;
    text-align: left;
    width: 100%;
    padding-top: 5px;
`;

const ByLine_Sm = styled.div`
    font-size: 12px;
    text-align: left;
    width: 100%;
`;

const Goto = styled(Link)`
    margin: 0;
    color: #202020;
    text-decoration: none;
`;

export {
    Container, 
    Wrapper,  
    Article_Lg, 
    ArticleImg, 
    ArticleTitle_Lg, 
    ByLine_Lg, 
    Article_Sm,
    ArticleTitle_Sm,
    ByLine_Sm,  
    Goto,  
}